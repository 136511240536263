import React, { useState, useEffect } from 'react';
import GoogleMapReact from 'google-map-react';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CloseIcon from '@mui/icons-material/Close';
import { Paper, Typography, Button, Box, IconButton } from '@mui/material';
import { styled } from '@mui/system';
import moment from 'moment';
import mapStyles from './mapStyles';

const MapContainer = styled('div')({
  height: '85vh',
  width: '100%',
});

const MarkerContainer = styled('div')({
  position: 'absolute',
  transform: 'translate(-50%, -50%)',
  '&:hover': { zIndex: 1 },
});

const Map = ({ setCoordinates, setBounds, coordinates, places, language = 'TH', setChildClicked }) => {
  const [visibleMarkers, setVisibleMarkers] = useState([]);
  const [groupedPlaces, setGroupedPlaces] = useState({});

  useEffect(() => {
    if (places.length > 0) {
      const grouped = places.reduce((acc, place) => {
        const key = `${place.latitude}-${place.longitude}`;
        if (!acc[key]) acc[key] = [];
        acc[key].push(place);
        return acc;
      }, {});
      setGroupedPlaces(grouped);
      setVisibleMarkers(Object.keys(grouped));
    } else {
      setGroupedPlaces({});
      setVisibleMarkers([]);
    }
  }, [places]);

  const handleMapClick = () => {
    setVisibleMarkers([]);
  };

  const handleMarkerClick = (key) => {
    setVisibleMarkers((prevVisibleMarkers) =>
      prevVisibleMarkers.includes(key)
        ? prevVisibleMarkers.filter(markerKey => markerKey !== key)
        : [...prevVisibleMarkers, key]
    );
  };

  const renderMarkers = () =>
    Object.keys(groupedPlaces).map((key) => {
      const [latitude, longitude] = key.split('-').map(Number);
      return (
        <MarkerContainer
          lat={latitude}
          lng={longitude}
          key={key}
          onClick={(e) => {
            e.stopPropagation();
            handleMarkerClick(key);
          }}
        >
          <LocationOnIcon color="primary" fontSize="large" />
          {visibleMarkers.includes(key) && (
            <Carousel events={groupedPlaces[key]} onClose={() => handleMarkerClick(key)} language={language} />
          )}
        </MarkerContainer>
      );
    });

  const Carousel = ({ events, onClose, language }) => {
    const [current, setCurrent] = useState(0);

    const handleNext = (e) => {
      e.stopPropagation();
      setCurrent((prev) => (prev + 1) % events.length);
    };

    const handlePrev = (e) => {
      e.stopPropagation();
      setCurrent((prev) => (prev - 1 + events.length) % events.length);
    };

    const handleEventClick = (event) => {
      window.open(`/event/${event.id}`, '_blank');
    };

    const displayDate = (date) => moment(date).format('DD-MMM-YY');

    const dateSeparator = language === 'TH' ? ' ถึง ' : ' to ';

    return (
      <Paper
        elevation={3}
        style={{ position: 'absolute', bottom: '100%', left: '-50%', padding: '10px', width: '200px', cursor: 'pointer' }}
        onClick={(e) => e.stopPropagation()}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <IconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              onClose();
            }}
            style={{ position: 'absolute', top: 0, right: 0 }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
        <Box mt={2} onClick={() => handleEventClick(events[current])}>
          <Typography variant="h6">{events[current].event_name}</Typography>
          <Typography variant="body2">
            {displayDate(events[current].event_date)}
            {events[current].event_end_date ? `${dateSeparator}${displayDate(events[current].event_end_date)}` : ''}
          </Typography>
          <Typography variant="body2">{events[current].event_location}</Typography>
          <img
            src={events[current].event_image_link || 'https://images.pexels.com/photos/3184183/pexels-photo-3184183.jpeg'}
            alt={events[current].event_name}
            style={{ width: '100%', height: 'auto' }}
          />
          {events.length > 1 && (
            <Box display="flex" justifyContent="space-between" mt={1}>
              <Button size="small" onClick={handlePrev}>Prev</Button>
              <Button size="small" onClick={handleNext}>Next</Button>
            </Box>
          )}
        </Box>
      </Paper>
    );
  };

  return (
    <MapContainer>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY }}
        defaultCenter={coordinates}
        center={coordinates}
        defaultZoom={14}
        margin={[50, 50, 50, 50]}
        options={{ disableDefaultUI: true, zoomControl: true, styles: mapStyles }}
        onChange={(e) => {
          setCoordinates({ lat: e.center.lat, lng: e.center.lng });
          setBounds({ ne: e.marginBounds.ne, sw: e.marginBounds.sw });
        }}
        onClick={handleMapClick}
        yesIWantToUseGoogleMapApiInternals
      >
        {renderMarkers()}
      </GoogleMapReact>
    </MapContainer>
  );
};

export default Map;
