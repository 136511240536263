import React, { useState, useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Header from '../components/Header/Header';
import List from '../components/List/List';
import Map from '../components/Map/Map';
import { getPlacesData } from '../api'; 
import { Box } from '@mui/material';

const Home = () => {
  const [places, setPlaces] = useState([]);
  const [filteredPlaces, setFilteredPlaces] = useState([]);
  const [childClicked, setChildClicked] = useState(null);
  const [coordinates, setCoordinates] = useState({ lat: 13.7652775, lng: 100.5375988 });
  const [bounds, setBounds] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [type, setType] = useState('All');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date(new Date().setDate(new Date().getDate() + 7)));
  const [language, setLanguage] = useState('TH');

  // Define the fetchEvents function
  const fetchEvents = async () => {
    if (bounds.sw && bounds.ne) {
      setIsLoading(true);
      const data = await getPlacesData(type, bounds, startDate, endDate, language);
      setPlaces(data?.filter((place) => place.event_name));
      setFilteredPlaces([]);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(({ coords: { latitude, longitude } }) => {
      setCoordinates({ lat: latitude, lng: longitude });
    });
  }, []);

  useEffect(() => {
    fetchEvents(); // Call fetchEvents on bounds, type, startDate, endDate, or language change
  }, [bounds, type, startDate, endDate, language]);

  const [showList, setShowList] = useState(false);

  const toggleList = () => {
    setShowList(!showList);
  };

  return (
    <div>
      <CssBaseline />
      <Header
        setCoordinates={setCoordinates}
        type={type}
        setType={setType}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        language={language}
        setLanguage={setLanguage}
        fetchEvents={fetchEvents} // Pass fetchEvents to Header
      />
      <Box sx={{ position: 'relative', height: 'calc(100vh - 64px)', overflow: 'hidden' }}>
        <Map
          setCoordinates={setCoordinates}
          setBounds={setBounds}
          coordinates={coordinates}
          places={filteredPlaces.length ? filteredPlaces : places}
          setChildClicked={setChildClicked}
          language={language}
        />
        <Box
          sx={{
            position: 'absolute',
            top: '64px',
            right: '0',
            width: showList ? '300px' : '0',
            height: 'calc(100vh - 64px)',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            overflowY: 'auto',
            transition: 'width 0.3s',
            zIndex: 10,
          }}
        >
          {showList && (
            <List
              places={filteredPlaces.length ? filteredPlaces : places}
              childClicked={childClicked}
              isLoading={isLoading}
            />
          )}
        </Box>
        <Box
          sx={{
            position: 'absolute',
            top: '75px',
            right: '10px',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            color: 'white',
            borderRadius: '4px',
            cursor: 'pointer',
            zIndex: 11,
            padding: '5px',
          }}
          onClick={toggleList}
        >
          {showList ? 'Hide' : 'Show'} List
        </Box>
      </Box>
    </div>
  );
};

export default Home;
