import React from 'react';
import {
  Box,
  Typography,
  Button,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/system';
import moment from 'moment';
import 'moment/locale/th';

const ClickableTypography = styled(Typography)({
  cursor: 'pointer', // Change the cursor to pointer when hovering
});

const ClickableButton = styled(Button)({
  cursor: 'pointer', // Ensure the button shows the pointer cursor
});

const labels = {
  TH: {
    eventDate: 'เริ่มต้น',
    endDate: 'สิ้นสุด',
    website: 'เว็บไซต์'
  },
  EN: {
    eventDate: 'Start Date',
    endDate: 'End Date',
    website: 'Website'
  }
};

const PlaceDetails = ({ place, selected, refProp, closeInfoWindow, language = 'TH' }) => {
  if (selected) refProp?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });

  const handleEventNameClick = () => {
    window.open(`/event/${place.id}`, '_blank');
  };

  moment.locale(language === 'TH' ? 'th' : 'en');

  const displayDate = (date) => moment(date).format('DD-MMM-YY');

  return (
    <Card elevation={6}>
      <CardMedia
        component="img"
        style={{ width: '100%', objectFit: 'cover' }}
        image={place.event_image_link ? place.event_image_link : 'https://images.pexels.com/photos/3184183/pexels-photo-3184183.jpeg'}
        title={place.event_name}
      />
      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <ClickableTypography
            gutterBottom
            variant="h5"
            onClick={handleEventNameClick}
          >
            {place.event_name}
          </ClickableTypography>
          <IconButton size="small" onClick={closeInfoWindow}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="subtitle1">{labels[language].eventDate}</Typography>
          <Typography gutterBottom variant="subtitle1">
            {displayDate(place.event_date)}
          </Typography>
        </Box>
        {place.event_end_date && (
          <Box display="flex" justifyContent="space-between">
            <Typography variant="subtitle1">{labels[language].endDate}</Typography>
            <Typography gutterBottom variant="subtitle1">
              {displayDate(place.event_end_date)}
            </Typography>
          </Box>
        )}
        <Box display="flex" justifyContent="space-between">
          <Typography gutterBottom variant="subtitle1">{place.event_summary}</Typography>
        </Box>
        <CardActions>
          {place.website_link && (
            <ClickableButton size="small" color="primary" onClick={() => window.open(place.website_link, '_blank')}>
              {labels[language].website}
            </ClickableButton>
          )}
        </CardActions>
      </CardContent>
    </Card>
  );
};

export default PlaceDetails;
