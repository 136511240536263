import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Container, Paper, Box, Link, Grid } from '@mui/material';
import { styled } from '@mui/system';
import moment from 'moment';
import 'moment/locale/th';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import DescriptionIcon from '@mui/icons-material/Description';
import LanguageIcon from '@mui/icons-material/Language';

const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5000';

const StyledContainer = styled(Container)(({ theme }) => ({
  backgroundColor: '#E8F5E9', // Light mint green background
  padding: '20px',
  borderRadius: '12px',
  margin: '20px auto',
  width: '100%',
  maxWidth: '1000px',
  height: 'calc(100vh - 40px)', // Subtract margin from viewport height
  overflowY: 'auto', // Enable vertical scrolling
  [theme.breakpoints.down('sm')]: {
    padding: '10px',
  },
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: '30px',
  backgroundColor: '#FFFFFF',
  borderRadius: '8px',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  [theme.breakpoints.down('sm')]: {
    padding: '20px',
  },
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  color: '#2E7D32', // Dark green
  fontWeight: 700,
  marginBottom: '10px',
  display: 'flex',
  alignItems: 'center',
  '& svg': {
    marginRight: '10px',
    color: '#4CAF50', // Lighter green for icons
  },
}));

const EventTitle = styled(Typography)(({ theme }) => ({
  color: '#1B5E20', // Very dark green
  fontWeight: 700,
  fontSize: '2.75rem',
  marginBottom: '20px',
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    fontSize: '2.25rem',
  },
}));

const EventSummary = styled(Typography)(({ theme }) => ({
  color: '#388E3C', // Medium green
  fontWeight: 500,
  fontSize: '1.75rem',
  marginBottom: '30px',
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.5rem',
  },
}));

const StyledLink = styled(Link)({
  color: '#43A047', // Slightly lighter green
  fontWeight: 500,
  '&:hover': {
    color: '#66BB6A', // Light green on hover
  },
});

const EventDetail = () => {
  const { id } = useParams();
  const [event, setEvent] = useState(null);

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/events/${id}`);
        const data = await response.json();
        setEvent(data);
      } catch (error) {
        console.error('Error fetching event:', error);
      }
    };

    fetchEvent();
  }, [id]);

  if (!event) return <div>Loading...</div>;

  const isThai = event.language === 'TH';
  moment.locale(isThai ? 'th' : 'en');

  const displayDate = (date) => moment(date).format('DD MMMM YYYY');

  return (
    <StyledContainer>
      <StyledPaper elevation={3}>
        <EventTitle variant="h1">{event.event_name}</EventTitle>
        <EventSummary variant="h2">{event.event_summary}</EventSummary>
        
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <SectionTitle variant="h3">
              <CalendarTodayIcon />
              {isThai ? 'วันที่' : 'Date'}
            </SectionTitle>
            <Typography variant="body1" paragraph style={{ fontSize: '1.7rem', color: '#333333' }}>
              {displayDate(event.event_date)}
              {event.event_end_date && ` - ${displayDate(event.event_end_date)}`}
            </Typography>

            <SectionTitle variant="h3">
              <LocationOnIcon />
              {isThai ? 'สถานที่' : 'Location'}
            </SectionTitle>
            <Typography variant="body1" paragraph style={{ fontSize: '1.7rem', color: '#333333' }}>
              {event.event_location}
            </Typography>
          </Grid>

          <Grid item xs={12} md={6}>
            <SectionTitle variant="h3">
              <DescriptionIcon />
              {isThai ? 'รายละเอียด' : 'Details'}
            </SectionTitle>
            <Typography variant="body1" paragraph style={{ fontSize: '1.7rem', color: '#333333', whiteSpace: 'pre-wrap' }}>
              {event.event_description}
            </Typography>
          </Grid>
        </Grid>

        <Box mt={4}>
          <SectionTitle variant="h3">
            <LanguageIcon />
            {isThai ? 'เว็บไซต์' : 'Website'}
          </SectionTitle>
          <Typography variant="body1" paragraph style={{ fontSize: '1.7rem' }}>
            <StyledLink href={event.website_link} target="_blank" rel="noopener noreferrer">
              {event.website_link}
            </StyledLink>
          </Typography>
        </Box>

        {event.event_image_link && (
          <Box mt={4}>
            <img
              src={event.event_image_link}
              alt={event.event_name}
              style={{ width: '100%', height: 'auto', borderRadius: '8px' }}
            />
          </Box>
        )}

        <Box mt={4}>
          <SectionTitle variant="h3">
            <LocationOnIcon />
            {isThai ? 'แผนที่' : 'Map'}
          </SectionTitle>
          <iframe
            title="Event Location"
            width="100%"
            height="400"
            frameBorder="0"
            style={{ border: 0, borderRadius: '8px' }}
            src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}&q=${event.latitude},${event.longitude}`}
            allowFullScreen
          />
        </Box>
      </StyledPaper>
    </StyledContainer>
  );
};

export default EventDetail;