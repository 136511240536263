import React, { useState, useEffect, createRef } from 'react';
import {
  CircularProgress,
  Grid,
  Box
} from '@mui/material';
import PlaceDetails from '../PlaceDetails/PlaceDetails';
import { styled } from '@mui/system';

const StyledContainer = styled(Box)({
  padding: '25px',
});

const LoadingContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '600px',
});

const ScrollableGrid = styled(Grid)({
  maxHeight: '65vh',  // Set a max height for the grid
  overflowY: 'auto',  // Enable vertical scrolling
  paddingRight: '10px'  // Add some padding to avoid hiding content under the scrollbar
});

const List = ({ places, childClicked, isLoading }) => {
  const [elRefs, setElRefs] = useState([]);

  useEffect(() => {
    setElRefs((refs) => Array(places.length).fill().map((_, i) => refs[i] || createRef()));
  }, [places]);

  return (
    <StyledContainer>
      {isLoading ? (
        <LoadingContainer>
          <CircularProgress size="5rem" />
        </LoadingContainer>
      ) : (
        <ScrollableGrid container spacing={3}>
          {places?.map((place, i) => (
            <Grid ref={elRefs[i]} key={i} item xs={12}>
              <PlaceDetails selected={Number(childClicked) === i} refProp={elRefs[i]} place={place} />
            </Grid>
          ))}
        </ScrollableGrid>
      )}
    </StyledContainer>
  );
};

export default List;
