import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5000';

export const getPlacesData = async (type, bounds, startDate, endDate, language) => {
  try {
    const { data } = await axios.get(`${apiUrl}/api/events`, {
      params: {
        type: type !== 'All' ? type : null,
        bl_latitude: bounds.sw.lat,
        tr_latitude: bounds.ne.lat,
        bl_longitude: bounds.sw.lng,
        tr_longitude: bounds.ne.lng,
        startDate: startDate ? new Date(startDate).toISOString() : null,
        endDate: endDate ? new Date(endDate).toISOString() : null,
        language: language,
      },
    });
    return data;
  } catch (error) {
    console.error('Error fetching places data:', error);
    return [];
  }
};
