import React, { useState, useEffect } from 'react';
import {
  Container,
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
  Typography,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Box
} from '@mui/material';
import axios from 'axios';
import moment from 'moment-timezone';

const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5000';

const ApprovalPage = () => {
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [comments, setComments] = useState('');
  const [approvalStatus, setApprovalStatus] = useState('');

  useEffect(() => {
    const fetchPendingEvents = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/events/pending`);
        setEvents(response.data);
      } catch (error) {
        console.error('Error fetching pending events:', error);
      }
    };

    fetchPendingEvents();
  }, []);

  const updateEvent = async (event, status) => {
    const eventData = {
      id: event.id,
      eventName: event.event_name,
      eventDate: moment(selectedEvent.event_date).utc().format(), // Convert to UTC before submission
      eventEndDate: selectedEvent.event_end_date ? moment(selectedEvent.event_end_date).utc().format() : null, // Convert to UTC before submission
      eventLocation: selectedEvent.event_location,
      eventDescription: selectedEvent.event_description,
      eventImageLink: selectedEvent.event_image_link || event.event_image_link,
      latitude: selectedEvent.latitude ? parseFloat(selectedEvent.latitude) : null,
      longitude: selectedEvent.longitude ? parseFloat(selectedEvent.longitude) : null,
      websiteLink: selectedEvent.website_link,
      eventSummary: selectedEvent.event_summary,
      language: selectedEvent.language,
      type: selectedEvent.type,
      status: status,
      comments: status === 'rejected' ? comments : selectedEvent.comments,
      userName: selectedEvent.user_name // Ensure userName is included
    };

    try {
      const response = await axios.put(`${apiUrl}/api/events/${event.id}`, eventData);
      setApprovalStatus(`Event ${response.data.event_name} ${status === 'approved' ? 'approved' : status === 'rejected' ? 'rejected' : 'updated'} successfully!`);
      setEvents(events.filter(ev => ev.id !== event.id));
      setSelectedEvent(null);
    } catch (error) {
      console.error(`Error ${status} event:`, error);
      setApprovalStatus(`Error ${status} event. Please try again.`);
    }
  };

  const handleApprove = async (event) => {
    await updateEvent(event, 'approved');
  };

  const handleReject = async (event) => {
    await updateEvent(event, 'rejected');
  };

  const handleSaveChanges = async () => {
    if (!selectedEvent) return;

    await updateEvent(selectedEvent, 'pending');
  };

  const handleChange = (e, field) => {
    const value = e.target.value;

    setSelectedEvent(prevState => ({
      ...prevState,
      [field]: value
    }));
  };

  const handleSelectEvent = (event) => {
    setSelectedEvent({
      ...event,
      event_date: moment.utc(event.event_date).local().format('YYYY-MM-DDTHH:mm'),
      event_end_date: event.event_end_date ? moment.utc(event.event_end_date).local().format('YYYY-MM-DDTHH:mm') : ''
    });
    setComments('');
  };

  const eventTypes = [
    { value: "Concerts", label: { EN: "Concerts", TH: "คอนเสิร์ต" } },
    { value: "Fair & Exhibitions", label: { EN: "Fair & Exhibitions", TH: "งานแสดงสินค้าและนิทรรศการ" } },
    { value: "Live Performances & Entertainment", label: { EN: "Live Performances & Entertainment", TH: "ความบันเทิงและการแสดงสด" } },
    { value: "Sports Events", label: { EN: "Sports Events", TH: "กิจกรรมกีฬา" } },
    { value: "Sports & Skill Competitions", label: { EN: "Sports & Skill Competitions", TH: "การแข่งขันกีฬาและความสามารถ" } },
    { value: "Travel & Tourism", label: { EN: "Travel & Tourism", TH: "การท่องเที่ยว" } },
    { value: "Promotions", label: { EN: "Promotions", TH: "โปรโมชั่นและส่วนลด" } },
    { value: "Other", label: { EN: "Other", TH: "อื่นๆ" } }
  ];

  return (
    <Container style={{ maxHeight: '100vh', overflowY: 'auto', padding: '20px' }}>
      <Typography variant="h4" gutterBottom>
        Approve or Reject Events
      </Typography>
      <Grid container spacing={3}>
        {events.length > 0 ? (
          events.map((event) => (
            <Grid item xs={12} key={event.id}>
              <Card>
                <CardContent>
                  <TextField
                    label="Event Name"
                    fullWidth
                    margin="normal"
                    value={selectedEvent && selectedEvent.id === event.id ? selectedEvent.event_name : event.event_name}
                    onChange={(e) => handleChange(e, 'event_name')}
                    disabled={selectedEvent && selectedEvent.id !== event.id}
                  />
                  <TextField
                    label="Event Date"
                    type="datetime-local"
                    fullWidth
                    margin="normal"
                    value={selectedEvent && selectedEvent.id === event.id ? selectedEvent.event_date : moment.utc(event.event_date).local().format('YYYY-MM-DDTHH:mm')}
                    onChange={(e) => handleChange(e, 'event_date')}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled={selectedEvent && selectedEvent.id !== event.id}
                  />
                  <TextField
                    label="Event End Date"
                    type="datetime-local"
                    fullWidth
                    margin="normal"
                    value={selectedEvent && selectedEvent.id === event.id ? selectedEvent.event_end_date : event.event_end_date ? moment.utc(event.event_end_date).local().format('YYYY-MM-DDTHH:mm') : ''}
                    onChange={(e) => handleChange(e, 'event_end_date')}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled={selectedEvent && selectedEvent.id !== event.id}
                  />
                  <TextField
                    label="Event Location"
                    fullWidth
                    margin="normal"
                    value={selectedEvent && selectedEvent.id === event.id ? selectedEvent.event_location : event.event_location}
                    onChange={(e) => handleChange(e, 'event_location')}
                    disabled={selectedEvent && selectedEvent.id !== event.id}
                  />
                  <TextField
                    label="Latitude"
                    fullWidth
                    margin="normal"
                    value={selectedEvent && selectedEvent.id === event.id ? selectedEvent.latitude : event.latitude}
                    onChange={(e) => handleChange(e, 'latitude')}
                    disabled={selectedEvent && selectedEvent.id !== event.id}
                  />
                  <TextField
                    label="Longitude"
                    fullWidth
                    margin="normal"
                    value={selectedEvent && selectedEvent.id === event.id ? selectedEvent.longitude : event.longitude}
                    onChange={(e) => handleChange(e, 'longitude')}
                    disabled={selectedEvent && selectedEvent.id !== event.id}
                  />
                  <TextField
                    label="Website Link"
                    fullWidth
                    margin="normal"
                    value={selectedEvent && selectedEvent.id === event.id ? selectedEvent.website_link : event.website_link}
                    onChange={(e) => handleChange(e, 'website_link')}
                    disabled={selectedEvent && selectedEvent.id !== event.id}
                  />
                  <TextField
                    label="Event Description"
                    fullWidth
                    multiline
                    rows={4}
                    margin="normal"
                    value={selectedEvent && selectedEvent.id === event.id ? selectedEvent.event_description : event.event_description}
                    onChange={(e) => handleChange(e, 'event_description')}
                    disabled={selectedEvent && selectedEvent.id !== event.id}
                  />
                  <TextField
                    label="Event Summary"
                    fullWidth
                    multiline
                    rows={4}
                    margin="normal"
                    value={selectedEvent && selectedEvent.id === event.id ? selectedEvent.event_summary : event.event_summary}
                    onChange={(e) => handleChange(e, 'event_summary')}
                    disabled={selectedEvent && selectedEvent.id !== event.id}
                  />
                  <TextField
                    label="Image Link"
                    fullWidth
                    margin="normal"
                    value={selectedEvent && selectedEvent.id === event.id ? selectedEvent.event_image_link : event.event_image_link}
                    onChange={(e) => handleChange(e, 'event_image_link')}
                    disabled={selectedEvent && selectedEvent.id !== event.id}
                  />
                  {selectedEvent && selectedEvent.id === event.id && selectedEvent.event_image_link && (
                    <img src={selectedEvent.event_image_link} alt="Event" style={{ width: '100%', maxHeight: '200px', objectFit: 'cover', marginTop: '10px' }} />
                  )}
                  <FormControl fullWidth margin="normal" disabled={selectedEvent && selectedEvent.id !== event.id}>
                    <InputLabel>Type</InputLabel>
                    <Select
                      value={selectedEvent && selectedEvent.id === event.id ? selectedEvent.type : event.type}
                      onChange={(e) => handleChange(e, 'type')}
                    >
                      {eventTypes.map((eventType, index) => (
                        <MenuItem key={index} value={eventType.value}>
                          {eventType.label.EN}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {selectedEvent && selectedEvent.latitude && selectedEvent.longitude && (
                    <iframe
                      width="100%"
                      height="300"
                      frameBorder="0"
                      style={{ border: 0, marginTop: '10px' }}
                      src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}&q=${selectedEvent.latitude},${selectedEvent.longitude}&zoom=14`}
                      allowFullScreen
                    ></iframe>
                  )}
                </CardContent>
                <CardActions>
                  {selectedEvent && selectedEvent.id === event.id ? (
                    <Box width="100%">
                      <TextField
                        label="Comments"
                        fullWidth
                        multiline
                        rows={4}
                        margin="normal"
                        value={comments}
                        onChange={(e) => setComments(e.target.value)}
                      />
                      <Button variant="contained" color="primary" onClick={() => handleApprove(selectedEvent)} style={{ marginTop: '10px', marginRight: '10px' }}>
                        Approve
                      </Button>
                      <Button variant="contained" color="secondary" onClick={() => handleReject(selectedEvent)} style={{ marginTop: '10px', marginRight: '10px' }}>
                        Reject
                      </Button>
                      <Button variant="contained" color="primary" onClick={handleSaveChanges} style={{ marginTop: '10px', marginRight: '10px' }}>
                        Save Changes
                      </Button>
                    </Box>
                  ) : (
                    <Button onClick={() => handleSelectEvent(event)}>Review</Button>
                  )}
                </CardActions>
              </Card>
            </Grid>
          ))
        ) : (
          <Typography variant="body1">No pending events found.</Typography>
        )}
      </Grid>
      {approvalStatus && (
        <Typography variant="body1" style={{ marginTop: '20px' }}>
          {approvalStatus}
        </Typography>
      )}
    </Container>
  );
};

export default ApprovalPage;
