import React, { useState } from 'react';
import { Autocomplete } from '@react-google-maps/api';
import {
  AppBar,
  InputBase,
  Box,
  Toolbar,
  Typography,
  Select,
  MenuItem,
  FormControl,
  TextField,
  IconButton,
  Menu,
  MenuItem as MenuItemMui
} from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { styled } from '@mui/system';
import ThaiFlag from './thai-flag.png';
import EnglishFlag from './english-flag.png';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const SearchStyledDiv = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.grey[300],
  '&:hover': {
    backgroundColor: theme.palette.grey[400],
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const SearchIconDiv = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const MyInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
  '&::placeholder': {
    color: theme.palette.grey[700],
    opacity: 1,
  },
}));

const WhiteSelect = styled(Select)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  height: '40px',
  width: '100px',
  '& .MuiSelect-select': {
    color: theme.palette.grey[700],
  },
}));

const WhiteTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  height: '40px',
  '& .MuiInputBase-root': {
    height: '100%',
  },
  '& .MuiInputLabel-root': {
    fontSize: '1rem',
  },
}));

const Header = ({ setCoordinates, type, setType, startDate, setStartDate, endDate, setEndDate, language, setLanguage, fetchEvents }) => {
  const [autocomplete, setAutocomplete] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  // Handle loading the Autocomplete object from Google Maps API
  const onLoad = (autoC) => setAutocomplete(autoC);
  
  // Handle place change in the search box
  const onPlaceChanged = () => {
    const lat = autocomplete.getPlace().geometry.location.lat();
    const lng = autocomplete.getPlace().geometry.location.lng();
    setCoordinates({ lat, lng });
  };

  // Handle opening and closing of the dropdown menu
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Handle the event to open the event input form
  const handleEventAddingRequest = () => {
    window.open('/eventinput', '_blank');
    handleMenuClose();
  };

  // Event handler for changing the start date
  const handleStartDateChange = (newDate) => {
    setStartDate(newDate);
    fetchEvents(); // Fetch events with the updated start date
  };

  // Event handler for changing the end date
  const handleEndDateChange = (newDate) => {
    setEndDate(newDate);
    fetchEvents(); // Fetch events with the updated end date
  };

  // Event handler for changing the event type
  const handleTypeChange = (event) => {
    const newType = event.target.value;
    setType(newType);
    fetchEvents(); // Fetch events with the updated event type
  };

  // Event handler for changing the language
  const handleLanguageChange = (lang) => {
    setLanguage(lang);
    setType('All'); // Reset event type to 'All' when language changes
    fetchEvents(); // Fetch events with the updated language
  };

  // Define event types based on the selected language
  const eventTypes = {
    EN: [
      { value: "All", label: "All" },
      { value: "Concerts", label: "Concerts" },
      { value: "Fair & Exhibitions", label: "Fair & Exhibitions" },
      { value: "Live Performances & Entertainment", label: "Live Performances & Entertainment" },
      { value: "Sports Events", label: "Sports Events" },
      { value: "Sports & Skill Competitions", label: "Sports & Skill Competitions" },
      { value: "Travel & Tourism", label: "Travel & Tourism" },
      { value: "Promotions", label: "Promotions" },
      { value: "Other", label: "Other" }
    ],
    TH: [
      { value: "All", label: "ทั้งหมด" },
      { value: "Concerts", label: "คอนเสิร์ต" },
      { value: "Fair & Exhibitions", label: "งานแสดงสินค้าและนิทรรศการ" },
      { value: "Live Performances & Entertainment", label: "ความบันเทิงและการแสดงสด" },
      { value: "Sports Events", label: "กิจกรรมกีฬา" },
      { value: "Sports & Skill Competitions", label: "การแข่งขันกีฬาและความสามารถ" },
      { value: "Travel & Tourism", label: "การท่องเที่ยว" },
      { value: "Promotions", label: "โปรโมชั่นและส่วนลด" },
      { value: "Other", label: "อื่นๆ" }
    ]
  };

  // Function to get the event map text based on the selected language
  const getEventMapText = () => {
    return language === 'TH'
      ? 'Event Map : สำรวจกิจกรรมรอบตัวคุณ'
      : "Event Map : Explore events around you";
  };

  return (
    <AppBar position="static">
      <Toolbar sx={{ flexWrap: 'wrap' }}>
        <Typography variant="h5" noWrap sx={{ color: 'white', flexGrow: 1, marginRight: '15px' }}>
          {getEventMapText()}
        </Typography>
        <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
          <SearchStyledDiv>
            <SearchIconDiv>
              <SearchIcon />
            </SearchIconDiv>
            <MyInputBase placeholder="Search for events" />
          </SearchStyledDiv>
        </Autocomplete>
        <Box sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
          <Typography sx={{ color: 'white', mr: 1 }}>Type</Typography>
          <FormControl variant="outlined">
            <WhiteSelect
              value={type}
              onChange={handleTypeChange}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
            >
              {eventTypes[language].map((eventType, index) => (
                <MenuItem key={index} value={eventType.value}>
                  {eventType.label}
                </MenuItem>
              ))}
            </WhiteSelect>
          </FormControl>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
          <Typography sx={{ color: 'white', mr: 1 }}>Start</Typography>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label=""
              value={startDate}
              onChange={handleStartDateChange}
              renderInput={(params) => <WhiteTextField {...params} placeholder="Start Date" sx={{ m: 1, width: '120px' }} />}
            />
          </LocalizationProvider>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
          <Typography sx={{ color: 'white', mr: 1 }}>End</Typography>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label=""
              value={endDate}
              onChange={handleEndDateChange}
              renderInput={(params) => <WhiteTextField {...params} placeholder="End Date" sx={{ m: 1, width: '120px' }} />}
            />
          </LocalizationProvider>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
          <IconButton onClick={() => handleLanguageChange('TH')}>
            <img src={ThaiFlag} alt="Thai" style={{ width: '30px', height: '30px' }} />
          </IconButton>
          <IconButton onClick={() => handleLanguageChange('EN')}>
            <img src={EnglishFlag} alt="English" style={{ width: '30px', height: '30px' }} />
          </IconButton>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
          <IconButton onClick={handleMenuOpen}>
            <ErrorOutlineIcon style={{ color: 'white' }} />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItemMui onClick={handleEventAddingRequest}>Event Adding Request</MenuItemMui>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
